.ant-alert {
  margin-bottom: 24px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-table-thead .ant-table-cell {
  font-weight: 700;
}

.ant-form-item-label label {
  white-space: normal;
}

.nl-card-dark {
  background-color: #001529 !important;
  border-color: #4a4a4a !important;
  transition: all 0.2s;
}

.nl-card-dark * {
  color: rgba(255, 255, 255, 0.65) !important;
}

.nl-card-dark .ant-card-actions {
  background-color: #001529;
  border-top: 1px solid #4a4a4a;
}

.nl-card-dark .ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #4a4a4a;
}

.nl-card-dark .ant-switch {
  background-color: rgb(24 144 255);
}

.nl-card-dark .ant-avatar-image {
  background-color: #ccc;
}

.ant-card {
  border-left: none;
  border-right: none;
}

.nl-header {
  margin: -30px -30px 20px;
  padding: 20px 30px;
  background-color: white;
  border-left: 2px #1890ff solid;
  border-bottom: 1px #f0f2f5 solid;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.nl-box {
  background-color: white;
  padding: 20px;
}

.nl-attributes {
  display: flex;
  margin-bottom: 8px;
}

.nl-attributes .ant-space-item {
  flex: 1 1 auto;
}

.nl-attributes .ant-space-item:first-child,
.nl-attributes .ant-space-item:last-child {
  flex: initial;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.ant-table-footer {
  padding: 0;
}

.nl-import-result .ant-result-subtitle {
  text-align: left;
}

.ant-input-search .ant-input-affix-wrapper,
.ant-input-search .ant-input {
  padding: 4.3px 11px;
}

.ant-table-tbody .ant-table-row {
  cursor: pointer;
}

.nl-order-print .bordered td {
  border: 1px solid black;
}

.nl-order-print {
  display: none;
}

@media print {
  .nl-order-print {
    display: block;
  }
}
.inline-uploaded > span {
  display: flex;
  flex-direction: row-reverse;
}

.inline-uploaded {
  display: flex;
  align-items: center;
}

.ant-layout-rtl .react-tel-input .selected-flag {
  padding: 0 8px 0 0;
}

.ant-layout-rtl .react-tel-input .selected-flag .arrow {
  left: initial;
  right: 30px;
}

.ant-layout-rtl .react-tel-input .country-list .flag {
  margin-right: 0;
  margin-left: 7px;
}
.ant-form-rtl .ant-form-item-label {
  text-align: right !important;
}

.ant-pagination-line {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ant-pagination-line .ant-pagination-total-text {
  width: 100%;
  text-align: center;
}

[dir='rtl'] .react-tel-input .flag-dropdown {
  border-radius: 0 3px 3px 0;
}

[dir='rtl'] .react-tel-input .selected-flag {
  padding: 0 8px 0 0;
  border-radius: 0 3px 3px 0;
}

[dir='rtl'] .react-tel-input .flag-dropdown.open {
  border-radius: 0 3px 0 0;
}

[dir='rtl'] .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 0 3px 0 0;
}

.cg-uploadBlog .ant-upload {
  width: 100% !important;
  height: 200px !important;
}

.cg-uploadBlog .ant-image,
.cg-uploadBlog .ant-image img {
  height: 100%;
}
.left-align{
  display: flex;
  justify-content: end;
}

/* New CSS Start */
.custom-tabs-section .ant-tabs {
    flex-wrap: wrap;
}

.custom-tabs-section .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,.custom-tabs-section .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    white-space: normal;
}
.custom-tabs-section .ant-tabs-content-holder {
    width: 65%;
}
.custom-tabs-section .ant-tabs-nav {
    width: 35%;
}

.custom-tabs-section .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab {
    text-align: inherit;
}
.custom-tabs-section .ant-tabs-tab-btn > span {
    display: flex;
    align-items: flex-start;
}
.custom-tabs-section .ant-tabs-tab .anticon {
    margin-top: 5px;
}
/* New CSS End */